import React, { useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import styles from '../pa_revisao.module.scss'
import { usePARevisao } from '../pa_revisao_context';
import { isNullish } from '../../../../utils/misc_utils';
import SelectInput from '../../../Misc/SelectInput/SelectInput';
import MaterialIcon from 'material-icons-react';

const FiltroRevisao = () => {
  const { listaRevisao, listaRevisaoPrio, setListaRevisaoFiltrada, setListaRevisaoPrioFiltrada, listaMarca, listaCanal, filterObj, setFilterObj, usuarioAnalista, usuarioAnalistaPrio, isAdmin, visaoAnalista, fetchRevisaoGrid, visaoAnalita, setVisaoAnalista } = usePARevisao();

  useEffect(() => {
    const listaFiltrada = listaRevisao.filter(item => {
      return (
        (isNullish(filterObj.nf_entrada) || item.nf_entrada.includes(filterObj.nf_entrada)) &&
        (isNullish(filterObj.produto) || item.produto.includes(filterObj.produto)) &&
        (isNullish(filterObj.cor_produto) || item.cor_produto.includes(filterObj.cor_produto)) &&
        (isNullish(filterObj.marca) || item.marca.includes(filterObj.marca.value)) &&
        (isNullish(filterObj.canal) || item.filial_revisao.includes(filterObj.canal.value))
      );
    });
    setListaRevisaoFiltrada([...listaFiltrada]);
  }, [filterObj, listaRevisao]);

  useEffect(() => {
    const listaFiltrada = listaRevisaoPrio.filter(item => {
      return (
        (isNullish(filterObj.nf_entrada) || item.nf_entrada.includes(filterObj.nf_entrada)) &&
        (isNullish(filterObj.produto) || item.produto.includes(filterObj.produto)) &&
        (isNullish(filterObj.cor_produto) || item.cor_produto.includes(filterObj.cor_produto)) &&
        (isNullish(filterObj.marca) || item.marca.includes(filterObj.marca.value)) &&
        (isNullish(filterObj.canal) || item.filial_revisao.includes(filterObj.canal.value))
      );
    });
    setListaRevisaoPrioFiltrada([...listaFiltrada]);
  }, [filterObj, listaRevisaoPrio]);

  const rgx = value => { return value.replace(/[^.0-9]*/g, '') }

  const FilterList = [
    { key: 'marca', label: 'Marca', type: 'select' },
    { key: 'canal', label: 'Canal', type: 'select' },
    { key: 'nf_entrada', label: 'NF Entrada', type: 'text' },
    { key: 'produto', label: 'Produto', type: 'text' },
    { key: 'cor_produto', label: 'Cor Produto', type: 'text' }
  ];

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      minWidth: '164px',
      padding: '0',
      borderColor: state.isFocused ? '#2684FF' : '#ddd',
      boxShadow: state.isFocused ? '0 0 0 1px #2684FF' : 'none',
      '&:hover': {
        borderColor: '#2684FF',
        cursor: 'pointer',
      },
      textAlign: 'left',
      cursor: 'pointer'
    }),
    menuPortal: provided => ({ ...provided, zIndex: 9999 })
  }

  return (
    <Form className={styles.filter_wrapper}>
      {FilterList.map((filter, index) => {
        return (filter.type === 'text' ?
          <Form.Group key={`text_${index}`} className={styles.filter_group}>
            <Form.Label className={styles.filter_label}>{filter.label}</Form.Label>
            <Form.Control
              className={styles.filter_control}
              type={filter.type}
              placeholder={filter.label}
              value={filterObj[filter.key] || ''}
              onChange={e => setFilterObj({ ...filterObj, [filter.key]: rgx(e.target.value) })}
              required={filter.required}
            />
          </Form.Group> :
          (usuarioAnalista || usuarioAnalistaPrio || isAdmin) ?
            <div key={`select_${index}`}>
              <SelectInput
                label={filter.label}
                style={{ minWidth: '120px', height: '31.25px' }}
                value={filterObj[filter.key] || null}
                onChange={e => setFilterObj({ ...filterObj, [filter.key]: e })}
                options={filter.key === 'marca' ? listaMarca : listaCanal}
                customSelectStyles={customSelectStyles}
              />
            </div> : null
        );
      })}
      <div className={styles.flex_section} style={{ marginLeft: 'auto' }}>
        <Button
          className={styles.btn_big_header}
          variant={'secondary'}
          onClick={fetchRevisaoGrid}
        >
          Atualizar Tabela
        </Button>
        {(usuarioAnalista || usuarioAnalistaPrio || isAdmin) &&
          <Button
            className={styles.btn_big_header}
            style={{ alignSelf: 'flex-end', fontWeight: '500', alignItems: 'center', display: 'flex' }}
            variant={'primary'}
            onClick={() => setVisaoAnalista(!visaoAnalista)}
          >
            {visaoAnalista ? 'Versão do Operador' : 'Versão do Analista'}
            &nbsp;
            <MaterialIcon
              icon="autorenew"
              size={15}
              color='#FFFFFF'
            />
          </Button>
        }
      </div>
    </Form>
  );
}

export default FiltroRevisao;
