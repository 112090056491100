import React from 'react';
import { Button } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styles from './tabela_dnd.module.scss';
import { usePARevisao } from '../../PA/Revisao/pa_revisao_context';

const TabelaDND = () => {
  const { listaRevisaoPrio, setListaRevisaoPrio, listaRevisaoPrioFiltrada } = usePARevisao();

  const revisaoColumns = [
    {
      name: 'Prioridade', selector: row => {
        const index = listaRevisaoPrio.findIndex(obj => obj.id_entrada_fiscal === row.id_entrada_fiscal);
        return (
          <div className={styles.prioridade_wrapper}>
            {row.priorizado ? index + 1 : ' - '}
          </div>
        );
      }
    },
    { name: 'NF Entrada', selector: row => row.nf_entrada },
    { name: 'Serie NF', selector: row => row.serie_nf },
    { name: 'Origem', selector: row => row.origem },
    { name: 'Filial Revisão', selector: row => row.filial_revisao },
    { name: 'Produto', selector: row => row.produto },
    { name: 'Desc. Produto', selector: row => row.descricao_produto },
    { name: 'Cor', selector: row => row.cor_produto },
    { name: 'Desc. Cor', selector: row => row.descricao_cor },
    { name: 'Qtde.', selector: row => row.qtde_entrada },
    { name: 'Ordem Produção', selector: row => row.ordem_producao },
    { name: 'Coleção', selector: row => row.colecao },
    { name: 'Linha', selector: row => row.linha },
    { name: 'Preço', selector: row => row.preco },
    { name: 'Data Entrada Revisão', selector: row => row.data_entrada_revisao_br }
  ];

  const handleOnDragEnd = (resultado) => {
    if (!resultado.destination) return;

    const tarefasReordenadas = Array.from(listaRevisaoPrio);
    const [tarefaMovida] = tarefasReordenadas.splice(resultado.source.index, 1);
    tarefasReordenadas.splice(resultado.destination.index, 0, tarefaMovida);

    const tarefasPriorizadas = tarefasReordenadas.filter(tarefa => tarefa.priorizado);
    const tarefasNaoPriorizadas = tarefasReordenadas.filter(tarefa => !tarefa.priorizado);

    setListaRevisaoPrio([...tarefasPriorizadas, ...tarefasNaoPriorizadas]);
  }

  const sortProducao = (array) => {
    const itensPriorizados = array.filter(item => item.priorizado);
    const itensNaoPriorizados = array.filter(item => !item.priorizado);

    const itensNaoPriorizadosOrdenados = itensNaoPriorizados.sort((a, b) => {
      if (!a.data_registro && b.data_registro) return 1;
      if (a.data_registro && !b.data_registro) return -1;
      if (!a.data_registro && !b.data_registro) return 0;
      if (a.data_registro && b.data_registro) {
        const dateComparison = new Date(a.data_registro) - new Date(b.data_registro);
        if (dateComparison !== 0) return dateComparison;
      }

      if (a.id_entrada_fiscal && !b.id_entrada_fiscal) return -1;
      if (!a.id_entrada_fiscal && b.id_entrada_fiscal) return 1;
      if (!a.id_entrada_fiscal && !b.id_entrada_fiscal) return 0;
      return a.id_entrada_fiscal - b.id_entrada_fiscal;
    });

    return [...itensPriorizados, ...itensNaoPriorizadosOrdenados];
  }

  const alternarPriorizacao = (id_entrada_fiscal) => {
    const producaoAtualizada = listaRevisaoPrio.map(item =>
      item.id_entrada_fiscal === id_entrada_fiscal ? { ...item, priorizado: !item.priorizado } : item
    );

    setListaRevisaoPrio([...sortProducao(producaoAtualizada)]);
  }

  const alternarTudo = () => {
    const todosPriorizados = listaRevisaoPrioFiltrada.every(item => item.priorizado);

    const producaoAtualizada = listaRevisaoPrio.map(item =>
      listaRevisaoPrioFiltrada.some(filtrado => filtrado.id_entrada_fiscal === item.id_entrada_fiscal)
        ? { ...item, priorizado: todosPriorizados ? false : true }
        : item
    );

    setListaRevisaoPrio([...sortProducao(producaoAtualizada)]);
  }

  const alternarSegundaQualidade = (id_entrada_fiscal) => {
    const producaoAtualizada = listaRevisaoPrio.map(item =>
      item.id_entrada_fiscal === id_entrada_fiscal ? { ...item, nf_segunda_qualidade: item.nf_segunda_qualidade ? false : true } : item
    );
    setListaRevisaoPrio([...producaoAtualizada]);
  }

  return (
    <div className={styles.datatable_wrapper}>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="tarefas" direction="vertical">
          {(provided) => (
            <table className={styles.customTable} {...provided.droppableProps} ref={provided.innerRef}>
              <thead>
                <tr>
                  <th>
                    <Button
                      variant={'warning'}
                      onClick={alternarTudo}
                      style={{ fontWeight: '500' }}
                      disabled={listaRevisaoPrio.length === listaRevisaoPrioFiltrada.length}
                    >
                      {/* {tarefa.priorizado ? 'Marcar Tudo' : 'Desmarcar Tudo'} */}
                      {listaRevisaoPrioFiltrada.every(item => item.priorizado) ? 'Despriorizar' : 'Priorizar'}
                    </Button>
                  </th>
                  <th>Segunda Qualidade</th>
                  {revisaoColumns.map((coluna, index) => (
                    <th key={index}>{coluna.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {listaRevisaoPrioFiltrada.map((tarefa, index) => (
                  <Draggable
                    key={`unq_key__${tarefa.id_entrada_fiscal}`}
                    draggableId={`unq_key__${tarefa.id_entrada_fiscal}`}
                    index={index}
                    isDragDisabled={!tarefa.priorizado}
                  >
                    {(provided) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`${styles.draggableRow} ${tarefa.priorizado ? styles.prioritized : ''}`}
                      >
                        <td>
                          <Button
                            variant={tarefa.priorizado ? 'warning' : 'primary'}
                            onClick={() => alternarPriorizacao(tarefa.id_entrada_fiscal)}
                            style={{ fontWeight: '500' }}
                          >
                            {tarefa.priorizado ? 'Despriorizar' : 'Priorizar'}
                          </Button>
                        </td>
                        <td>
                          <Button
                            variant={tarefa.nf_segunda_qualidade ? 'danger' : 'success'}
                            onClick={() => alternarSegundaQualidade(tarefa.id_entrada_fiscal)}
                            style={{ fontWeight: '500' }}
                          >
                            {tarefa.nf_segunda_qualidade ? 'Sim' : 'Não'}
                          </Button>
                        </td>
                        {revisaoColumns.map((coluna, colIndex) => (
                          <td key={colIndex}>
                            {coluna.cell ? coluna.cell(tarefa) : coluna.selector(tarefa)}
                          </td>
                        ))}
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            </table>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default TabelaDND;
