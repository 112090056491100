import { Modal, Button } from "react-bootstrap";
import styles from '../pa_revisao.module.scss';
import { usePARevisao } from "../pa_revisao_context";
import Tabela from "../../../Misc/Tabela/Tabela";
import { useEffect } from "react";

const PARevisaoModalLocalizacao = () => {
  const { showModalLocalizacao, setShowModalLocalizacao, informacoesCarregadas, informacaoAtiva } = usePARevisao();

  const localizacao_columns = [
    { name: 'Localização', selector: row => row.localizacao_produto },
    { name: 'Descrição', selector: row => row.desc_localizacao_produto }
  ];

  return (
    <Modal
      show={showModalLocalizacao}
      centered
      size={'lg'}
      onHide={() => setShowModalLocalizacao(false)}
      backdropClassName={styles.backdrop_z_override}
    >
      <Modal.Header style={{ fontWeight: '500' }} closeButton>
        Localizações Possíveis | Produto: {informacoesCarregadas[informacaoAtiva].produto} | Cor: {informacoesCarregadas[informacaoAtiva].cor_produto}
      </Modal.Header>
      <Modal.Body className={styles.modal_body} style={{ fontWeight: '500' }}>
        <Tabela
          columns={localizacao_columns}
          data={informacoesCarregadas[informacaoAtiva].localizacoes}
          pagination={false}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={'success'}
          style={{ margin: '0px auto' }}
          className={styles.footer_btn_processamento}
          onClick={() => setShowModalLocalizacao(false)}
        >
          Voltar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PARevisaoModalLocalizacao;
