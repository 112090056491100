import { Modal, Button } from "react-bootstrap";
import { usePARevisao } from "../pa_revisao_context";
import styles from '../pa_revisao.module.scss';

const PARevisaoModalDespriorizacao = () => {
  const { showModalDespriorizacao, setShowModalDespriorizacao, handleDespriorizarTudo } = usePARevisao();

  return (
    <Modal
      show={showModalDespriorizacao}
      centered={true}
    >
      <Modal.Body>
        <span style={{ fontWeight: '500' }}>Tem certeza que deseja despriorizar TODAS as produções?<br />Essa decisão é irreversível.</span>
      </Modal.Body>
      <Modal.Footer className={styles.modal_footer}>
        <Button
          className={styles.btn_footer}
          variant={'secondary'}
          style={{ width: '33%', fontSize: '13px', height: '36px', fontWeight: 500 }}
          onClick={() => setShowModalDespriorizacao(false)}
        >
          Voltar
        </Button>
        <Button
          className={styles.btn_footer}
          variant={'danger'}
          style={{ width: '33%', fontSize: '13px', height: '36px', fontWeight: 500 }}
          onClick={handleDespriorizarTudo}
        >
          Despriorizar Tudo
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PARevisaoModalDespriorizacao;
