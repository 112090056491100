import { Modal, Button, Spinner } from 'react-bootstrap';
import styles from '../pa_revisao.module.scss';
import Tabela from '../../../Misc/Tabela/Tabela';
import { usePARevisao } from '../pa_revisao_context';
import { useEffect, useState } from 'react';
import MaterialIcon from 'material-icons-react';

const PARevisaoModalInfo = () => {
  const { showModalInformacoes, gradeRevisao, setShowModalLocalizacao, carregandoChamados, carregandoDefeitosPit, fetchDefeitos, fetchChamados, informacoesCarregadas, informacaoAtiva, fecharModalInformacoes } = usePARevisao();
  const [defeitosPitArr, setDefeitosPitArr] = useState([]);
  const [informacoes, setInformacoes] = useState({});

  const grade_columns = [
    { name: 'Tamanho', selector: row => row.tamanho },
    { name: 'Quantidade', selector: row => row.quantidade_total }
  ];

  useEffect(() => {
    const defeitosPitList = [];
    const defeitosPitAtivos = informacoesCarregadas[informacaoAtiva].defeitosPit;

    if (!defeitosPitAtivos) return;
    for (const item of defeitosPitAtivos) {
      const listItem = Object.keys(item).reduce((arr, key) => {
        if (item[key] === true && key !== 'outros') arr.push(key);
        return arr;
      }, []);

      if (item.outros === true) listItem.push(item.s_outros);
      defeitosPitList.push(listItem);
    }

    setDefeitosPitArr([...defeitosPitList]);
  }, [informacoesCarregadas]);

  useEffect(() => {
    setInformacoes({ ...informacoesCarregadas[informacaoAtiva] });
  }, [informacoesCarregadas]);

  return (
    <Modal
      show={showModalInformacoes}
      size={'lg'}
      onHide={fecharModalInformacoes}
      centered={true}
      backdropClassName={styles.backdrop_z_override}
    >
      <Modal.Header closeButton style={{ fontWeight: '500' }}>
        Referência {informacoes.nf_segunda_qualidade ? '(Segunda Qualidade)' : ''} | NF: {informacoes.nf_entrada} | Produto: {informacoes.produto} | Cor: {informacoes.cor_produto}
      </Modal.Header>
      <Modal.Body className={styles.modal_body}>
        <section className={styles.dados_conferencia}>
          <span className={styles.accordion_item}>Nota Fiscal:<div className={styles.font_blue}>{informacoes.nf_entrada}</div></span>
          <span className={styles.accordion_item}>Série:<div className={styles.font_blue}>{informacoes.serie_nf}</div></span>
          <span className={styles.accordion_item}>Origem:<div className={styles.font_blue}>{informacoes.origem}</div></span>
          <span className={styles.accordion_item}>Produto:<div className={styles.font_blue}>{informacoes.produto}</div></span>
          <span className={styles.accordion_item}>Descrição Produto:<div className={styles.font_blue}>{informacoes.descricao_produto}</div></span>
          <span className={styles.accordion_item}>Cor Produto:<div className={styles.font_blue}>{informacoes.cor_produto}</div></span>
          <span className={styles.accordion_item}>Descrição Cor:<div className={styles.font_blue}>{informacoes.descricao_cor}</div></span>
          <span className={styles.accordion_item}>Filial Revisão:<div className={styles.font_blue}>{informacoes.filial_revisao}</div></span>
          <span className={styles.accordion_item}>Qtde. Entrada:<div className={styles.font_blue}>{informacoes.qtde_entrada}</div></span>
          <span className={styles.accordion_item}>Data Entrada Revisão:<div className={styles.font_blue}>{informacoes.data_entrada_revisao_br}</div></span>
          <span className={styles.accordion_item}>Ordem Produção:<div className={styles.font_blue}>{informacoes.ordem_producao}</div></span>
          <span className={styles.accordion_item}>Segunda Qualidade? <div className={styles.font_blue}>{informacoes.nf_segunda_qualidade ? 'Sim' : 'Não'}</div></span>
          {/* <span className={styles.accordion_item}><div className={styles.font_blue}></div></span> */}

          <div className={styles.separator} />

          <div className={styles.chamados_wrapper}>
            <div className={styles.chamados_list}>
              <span className={styles.accordion_item}>Chamados</span>&nbsp;
              <i
                onClick={() => fetchChamados(informacoes)}
                style={{ cursor: 'pointer' }}
              >
                <MaterialIcon
                  icon="autorenew"
                  size={15}
                  color='#000000'
                />
              </i>
              <br />
              {carregandoChamados ? <Spinner size={'sm'} /> :
                informacoes.chamados && informacoes.chamados.map((item, index) => {
                  return (
                    <div key={index}>
                      Chamado {item.id_divergencia} |&nbsp;
                      <a
                        href={`https://plm-colaborativo.somalabs.com.br/produtos/${item.id_producao_prog_prod}/${item.id_producao_ppp_op}/divergencias?open=${item.id_divergencia}`}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                      >
                        abrir chamado
                      </a>
                    </div>
                  );
                })}
              {informacoes.chamados && informacoes.chamados.length === 0 ? 'Nenhum chamado encontrado' : null}
            </div>
            <div className={styles.vertical_separator} />
            <div className={styles.chamados_list}>
              <span className={styles.accordion_item}>Defeitos declarados</span>&nbsp;
              <i
                onClick={() => fetchDefeitos(informacoes)}
                style={{ cursor: 'pointer' }}
              >
                <MaterialIcon
                  icon="autorenew"
                  size={15}
                  color='#000000'
                />
              </i>
              <br />
              {carregandoDefeitosPit ? <Spinner size={'sm'} /> :
                defeitosPitArr.map((item, index) => {
                  return (
                    <div key={index}>
                      Código Espelho {informacoes.defeitosPit[index].codigo_espelho} |&nbsp;
                      {item.length > 0 ? item.join(', ') : 'Nenhum defeito declarado'}
                    </div>
                  );
                })}
              {defeitosPitArr.length === 0 ? 'Nenhum defeito declarado' : null}
            </div>
          </div>

          <div className={styles.separator} />

          <span className={styles.accordion_item_big}>
            <Button
              style={{ fontWeight: '500', margin: 'auto' }}
              variant={'primary'}
              onClick={() => setShowModalLocalizacao(true)}
            >
              Localização
            </Button>
          </span>
        </section>

        <section className={styles.grade_conferencia}>
          <label className={styles.form_label}>Grade (somente conferência)</label>
          <Tabela
            columns={grade_columns}
            data={gradeRevisao}
            pagination={false}
            rowStyles={{ height: '30px !important' }}
          />
        </section>
      </Modal.Body>
      <Modal.Footer className={styles.modal_footer}>
        <Button
          variant={'success'}
          onClick={fecharModalInformacoes}
          className={styles.footer_btn_processamento}
        >
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PARevisaoModalInfo;
