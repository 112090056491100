import React, { createContext, useState, useContext } from 'react';
import { toast, ToastContainer } from "react-toastify";
import ControleAcessoModal from '../components/ControleAcesso/modal_controle_acesso';

const MiscContext = createContext();
export const useMisc = () => useContext(MiscContext);

export const MiscProvider = ({ children }) => {
  const [showLoading, setShowLoading] = useState(false);
  const [showControleAcessoModal, setShowControleAcessoModal] = useState(false);
  const setToast = (type, message) => {
    message = message.split('\n');
    return toast[type](<>{message.map((str, index) => <span key={index}>{str}<br /></span>)}</>);
  }

  return (
    <MiscContext.Provider
      value={{ showLoading, setShowLoading, setToast, showControleAcessoModal, setShowControleAcessoModal }}
    >
      {children}

      {<ToastContainer
        position={'top-center'}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />}

      {showControleAcessoModal && <ControleAcessoModal />}

    </MiscContext.Provider>
  );
}
