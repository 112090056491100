import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useMisc } from '../../../contexts/MiscContext';
import styles from './ModalCarregando.module.scss';

const ModalCarregando = () => {
  const { showLoading } = useMisc();

  return (
    <Modal
      show={showLoading}
      centered={true}
      backdrop={true}
      backdropClassName={styles.backdrop_z_override}
    >
      <Modal.Header></Modal.Header>
      <Modal.Body className={styles.modal_body}>
        <Spinner></Spinner>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  )
}

export default ModalCarregando;