import { useState, React }  from "react";
import Select from 'react-select';
import { Button, Modal, Table, Form, Row, Col } from 'react-bootstrap';
import styles from '../../../styles/FarmGlobal/MateriaisComposicao.module.scss';
import { useFGMateriaisComposicao } from "../../../contexts/FarmGlobal/MateriaisComposicaoContext";



const ModalCadComposicao = () => {
    const {
        showCad,
        setShowCad,
        handleSave,
        materialSelecionado,
        setMaterialSelecionado,
        materialComposicao,
        setMaterialComposicao,
        tiposFibra,
        fornecedoresPa,
        fornecedoresMp,
        setFile,
        handleAddComposicao,
        handleRemoveComposicao,
        handleChangeComposicao,
        handleChange,
        handleFileChange,
        handleChangeTipo,
        handleSaveUpperCase,
        removeAccents,
        fetchData,
        modalFornecedorShow, setModalFornecedorShow,
        modalFornecedorConfirmShow, setModalFornecedorConfirmShow,
        newFornecedor, setNewFornecedor,
        fornecedorType, setFornecedorType,
        inputFornecedorValue, setInputFornecedorValue  ,        
        handleFornecedorCreate,
        handleFornecedorConfirm,
        handleFornecedorSave, 
    } = useFGMateriaisComposicao();
    
    
  const handleKeyDown = (e, type) => {
    if (e.key === 'Enter' && inputFornecedorValue) {    
      handleFornecedorCreate(inputFornecedorValue, type);
    }
  };


    return (
        <>
            <Modal
                show={showCad}
                onHide={() => setShowCad(false)}
                backdrop="static"
                keyboard={false}
                centered
                size="xl"
            >
                <Modal.Header>
                    <Modal.Title>Cadastro de Base</Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Descrição Material <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control name="descricao" value={removeAccents(materialSelecionado.descricao)} onChange={handleChange} 
                                          onKeyPress={(e) => {
                                             return removeAccents(e.key);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Código Fornecedor</Form.Label>
                                    <Form.Control
                                        name="CodigoFornecedor"
                                        type="text"
                                        value={materialSelecionado.CodigoFornecedor}
                                        onChange={handleChange}
                                        min="1"
                                        onKeyPress={(e) => {
                                            if (e.key === '-') e.preventDefault();
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Gramatura (g/m²) <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control
                                        name="gramatura"
                                        type="number"
                                        value={materialSelecionado.gramatura}
                                        onChange={handleChange}
                                        min="1"
                                        onKeyPress={(e) => {
                                            if (e.key === '-') e.preventDefault();
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Tipo <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <div>
                                        <Form.Check
                                            inline
                                            label="Tinto"
                                            type="checkbox"
                                            checked={materialSelecionado.tipo === 'T'}
                                            onChange={() => handleChangeTipo('T')}
                                        />
                                        <Form.Check
                                            inline
                                            label="Estampado Cilindro"
                                            type="checkbox"
                                            checked={materialSelecionado.tipo === 'EC'}
                                            onChange={() => handleChangeTipo('EC')}
                                        />
                                         <Form.Check
                                            inline
                                            label="Estampado Digital"
                                            type="checkbox"
                                            checked={materialSelecionado.tipo === 'ED'}
                                            onChange={() => handleChangeTipo('ED')}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Ref Linx</Form.Label>
                                    <Form.Control name="refLinx" value={materialSelecionado.refLinx} onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            
                        </Row>
                        <Row>
                            <Col md={6}>
                            <Form.Group>
                                <Form.Label>Fornecedor MP</Form.Label>
                                <Select
                                options={fornecedoresMp.map(f => ({
                                    label: f.Fornecedor,
                                    value: f.IdFornecedor
                                }))}
                                value={fornecedoresMp.find(f => f.IdFornecedor == materialSelecionado.idFornecedorMp) 
                                ? { label: fornecedoresMp.find(f => f.IdFornecedor == materialSelecionado.idFornecedorMp).Fornecedor, 
                                    value: materialSelecionado.idFornecedorMp 
                                    } 
                                : null}                           
                                onChange={(selected) =>
                                handleChange({ target: { name: 'idFornecedorMp', value: selected?.value } })
                                }                        
                                onInputChange={(value) => setInputFornecedorValue(value)} 
                                onKeyDown={(e) => handleKeyDown(e, 'Mp')} 
                                placeholder="Selecione ou digite para buscar..."
                                isSearchable
                                />
                            </Form.Group>
                            </Col>
                            <Col md={6}>
                            <Form.Group>
                                <Form.Label>Fornecedor PA</Form.Label>
                                <Select
                                options={fornecedoresPa.map(f => ({
                                    label: f.Fornecedor,
                                    value: f.IdFornecedor
                                }))}
                                value={fornecedoresPa.find(f => f.IdFornecedor == materialSelecionado.idFornecedorPa) 
                                ? { label: fornecedoresPa.find(f => f.IdFornecedor == materialSelecionado.idFornecedorPa).Fornecedor, 
                                    value: materialSelecionado.idFornecedorPa
                                    } 
                                : null}  
                                onChange={(selected) =>
                                    handleChange({ target: { name: 'idFornecedorPa', value: selected?.value } })
                                }
                                onInputChange={(value) => setInputFornecedorValue(value)}
                                onKeyDown={(e) => handleKeyDown(e, 'Pa')}
                                placeholder="Selecione ou digite para buscar..."
                                isSearchable
                                />
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} style={{display:'none'}}>
                                <Form.Group>
                                    <Form.Label>Chave FG</Form.Label>
                                    <Form.Control name="chaveFg" readOnly={true} value={materialSelecionado?.chaveFg} onChange={handleChange} />
                                </Form.Group>
                            </Col>                           
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Ficha Técnica <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control type="file" accept=".pdf" onChange={handleFileChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <h5>Composição</h5>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>                                            
                                            <th>(%)</th>
                                            <th>Tipo de Fibra</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {materialComposicao.map((item, index) => (
                                            <tr key={index}>
                                            <td>
                                                    <Form.Control
                                                        type="number"
                                                        value={item.PercFibra}
                                                        onChange={(e) => handleChangeComposicao(index, 'PercFibra', e.target.value)}
                                                        min="1"
                                                        onKeyPress={(e) => {
                                                            if (e.key === '-') e.preventDefault();
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        as="select"
                                                        value={item.IdTipoFibra}
                                                        onChange={(e) => handleChangeComposicao(index, 'IdTipoFibra', e.target.value)}
                                                    >
                                                        {tiposFibra.map((tipo) => (
                                                            <option key={tipo.IdTipoFibra} value={tipo.IdTipoFibra}>
                                                                {tipo.TipoFibra}
                                                            </option>
                                                        ))}
                                                    </Form.Control>
                                                </td>
                                                
                                                <td>
                                                    <Button variant="danger" onClick={() => handleRemoveComposicao(index)}>
                                                        Remover
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <Button onClick={handleAddComposicao}>Adicionar Composição</Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCad(false)}>Cancelar</Button>
                    <Button variant="primary" onClick={handleSave}>Salvar</Button>
                </Modal.Footer>
            </Modal>

             {/* Modal for confirming the addition of a new supplier */}
            <Modal show={modalFornecedorShow} onHide={() => setModalFornecedorShow(false)}>
                <Modal.Header closeButton>
                <Modal.Title>Inserir Fornecedor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                Deseja inserir o fornecedor "{newFornecedor}" no sistema? Verifique se o nome foi digitado corretamente.
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => setModalFornecedorShow(false)}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={handleFornecedorConfirm}>
                    Sim
                </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal for final confirmation */}
            <Modal show={modalFornecedorConfirmShow} onHide={() => setModalFornecedorConfirmShow(false)}>
                <Modal.Header closeButton>
                <Modal.Title>Confirmação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                Atenção! Ao clicar em gravar, o fornecedor  "{newFornecedor}" será inserido no sistema.
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => setModalFornecedorConfirmShow(false)}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={handleFornecedorSave}>
                    Gravar
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalCadComposicao;
