import ExcelJS from "exceljs"
import axios from 'axios'
import { api } from "../services/api";
import { toUTCDDMMYYYY } from "./dateUtils";
import { toast } from 'react-toastify';

async function inserirImagens(images, workbook, worksheet, sessaoImagens) {
  try {
    let mostRecentRowBreak = null;
    let indexHelper = 0;

    for (let i = 0; i < images.length; i++) {
      const imageBuffer = images[i];

      if (i !== 0 && i % 3 === 0) {
        mostRecentRowBreak = worksheet.addRow();
        indexHelper = 0;
      }

      const imageData = new Uint8Array(imageBuffer.data);
      const imageId = workbook.addImage({
        buffer: imageData,
        extension: 'jpg'
      });

      const img = new Image();
      img.src = URL.createObjectURL(new Blob([imageData], { type: 'image/jpg' }));

      await new Promise((resolve, reject) => {
        img.onload = () => {
          resolve();
        };
        img.onerror = () => {
          reject(new Error("Falha ao carregar a imagem."));
        };
      });

      const aspectRatio = img.height / img.width;
      const imageWidthInCells = 8;
      const rowSpan = imageWidthInCells * 1.35 * aspectRatio;

      const startCol = i % 3 === 0 ? 0 : 10 * indexHelper;
      indexHelper++;
      const startRow = mostRecentRowBreak ? mostRecentRowBreak['_number'] - 1 : sessaoImagens['_number'];

      worksheet.addImage(imageId, {
        tl: { col: startCol, row: startRow },
        br: { col: startCol + imageWidthInCells, row: startRow + rowSpan }
      });
    }
  } catch (e) {
    toast.error('Ocorreu um erro durante a criação do(s) laudo(s).\nPor favor tente novamente ou entre em contato com o suporte.');
  }
}

export async function gerarFichaTecnica(refs) {
  const params = [];
  try {
    const resLaudos = await api.post('/pa-gerar-laudo/gerar-laudos', refs);

    for (let i = 0; i < resLaudos.data.length; i++) {
      const obj = resLaudos.data[i];
      const mainInfo1 = { ...obj.resLaudo1[0] }
      const mainInfo2 = { ...obj.resLaudo2[0] }
      const controleAprovacoes = [...obj.resLaudo3];
      const medidas = [...obj.resLaudo4];
      const destinoProducao = [...obj.resLaudo5];
      const totalPecasDefeitoGrade = [...obj.resLaudo6];
      const avaliacaoProducao = [...obj.resLaudo7];
      const analiseSegundaQualidade = [...obj.resLaudo8];
      const orientacoesConserto = [...obj.resLaudo9];
      const imagensProducao = [...obj.resLaudo10ImagensProducao];
      const imagensSegundaQualidade = [...obj.resLaudo10ImagensSegundaQualidade];

      params.push({
        nomeArquivo: `${mainInfo1.nf_entrada}_${mainInfo1.produto}_${mainInfo1.cor_produto}`,
        identEntradaFiscal1: [
          {
            nfEntrada: mainInfo1.nf_entrada,
            serieNf: mainInfo1.serie_nf,
            ordemProducao: mainInfo1.ordem_producao,
            valorItemNf: mainInfo1.valor_item_nf,
            dataDigitacao: toUTCDDMMYYYY(mainInfo1.data_digitacao),
            qtdeRecebida: mainInfo1.qtde_recebida
          }
        ],
        identEntradaFiscal2: [
          {
            compradora: mainInfo1.compradora,
            filialRevisao: mainInfo1.filial_revisao,
            fornecedor: mainInfo1.fornecedor,
            qtdeRecebido2Q: mainInfo1.qtde_recebida_segunda_qualidade,
            qtdeEnvidado2Q: mainInfo1.qtde_direcionada_segunda_qualidade
          }
        ],
        identProduto1: [
          {
            produto: mainInfo1.produto,
            descProduto: mainInfo1.descricao_produto,
            corProduto: mainInfo1.cor_produto,
            descCorProduto: mainInfo1.descricao_cor
          }
        ],
        identProduto2: [
          {
            marca: mainInfo1.marca,
            colecao: mainInfo1.colecao,
            estilista: mainInfo1.estilista,
            tipoProduto: mainInfo1.tipo_produto,
            linha: mainInfo1.linha,
            grupoProduto: mainInfo1.grupo_produto
          }
        ],
        statusQualidade: mainInfo2.status_qualidade,
        statusFinal: mainInfo2.status_final,
        dadosAnaliseCQ: [
          {
            area: 'REVISÃO',
            dataAnalise: mainInfo2.data_revisao,
            amostra: mainInfo2.qtde_pecas_amostra_revisao,
            porcRevisado: calcAmostra(mainInfo1.qtde_recebida, mainInfo1.qtde_recebida_segunda_qualidade, mainInfo2.qtde_pecas_amostra_revisao),
            colaborador: mainInfo2.colaborador_revisao,
            status: mainInfo2.status_revisao
          },
          {
            area: 'CHECKLIST',
            dataAnalise: mainInfo2.data_checklist,
            amostra: mainInfo2.qtde_pecas_amostra_checklist,
            porcRevisado: calcAmostra(mainInfo1.qtde_recebida, mainInfo1.qtde_recebida_segunda_qualidade, mainInfo2.qtde_pecas_amostra_checklist),
            colaborador: mainInfo2.colaborador_checklist,
            status: mainInfo2.status_checklist
          }
        ],
        identDesenvolvimento: [
          {
            modelagemEstilo: mainInfo2.status_modelagem_estilo,
            pecaLacrada: mainInfo2.status_peca_lacrada,
            corte: mainInfo2.status_corte,
            graduacao: mainInfo2.status_graduacao,
            beneficiamento: mainInfo2.status_beneficiamento,
            recPecaPiloto: mainInfo2.rec_piloto
          }
        ],
        controleAprovacoes: [],
        medidas: [],
        destinoProducao: [],
        totalPecasDefeitoGrade: [],
        avaliacaoProducao: [],
        analiseSegundaQualidade: [],
        orientacoesConserto: [],
        imagensProducao,
        imagensSegundaQualidade
      });

      // --- INSERE APROVAÇÕES --- //
      controleAprovacoes.forEach(obj => {
        obj = undefinedToEmptyStr(obj);
        params[i].controleAprovacoes.push(
          {
            area: obj.area_aprovacao,
            dataEnvio: obj.data_envio,
            motivoEnvio: obj.motivo_envio,
            status: obj.status_validacao,
            consideracao: obj.consideracao,
          }
        );
      });

      // --- INSERE MEDIDAS --- //
      medidas.forEach(obj => {
        obj = undefinedToEmptyStr(obj);
        params[i].medidas.push(
          {
            area: obj.area,
            grade: obj.grade,
            medida: obj.medida,
            tamanhoFT: obj.tamanho_ficha_tecnica,
            tolerancia: obj.tolerancia,
            tamanhoMedio: obj.tamanho_medio
          }
        );
      });

      // --- INSERE DESTINO DEVOLUÇÕES --- //
      destinoProducao.forEach(obj => {
        obj = undefinedToEmptyStr(obj);
        params[i].destinoProducao.push(
          {
            destinoProducao: obj.descricao_destino,
            qtdePecas: obj.qtde_pecas
          }
        );
      });

      // --- INSERE QTDE PEÇAS | DEFEITO GRADE --- //
      totalPecasDefeitoGrade.forEach(obj => {
        obj = undefinedToEmptyStr(obj);
        params[i].totalPecasDefeitoGrade.push(
          {
            tamanho: obj.tamanho,
            totalPecasDefeito: obj.qtde_pecas_defeito,
            totalRevisao: obj.qtde_pecas_defeito_revisao,
            totalChecklist: obj.qtde_pecas_defeito_checklist,
            totalSegundaQualidade: obj.qtde_pecas_defeito_segunda_revisao
          }
        );
      });

      // --- INSERE AVALIAÇÃO PRODUÇÃO --- //
      avaliacaoProducao.forEach(obj => {
        obj = undefinedToEmptyStr(obj);
        params[i].avaliacaoProducao.push(
          {
            area: obj.area,
            grupoAnalise: obj.grupo_defeito,
            grade: obj.descricao_grade,
            status: obj.status_defeito,
            defeito: obj.defeito,
            // localizacao_defeito: obj.localizacao_defeito
            consideracao: obj.consideracao
          }
        );
      });

      // --- INSERE SEGUNDA QUALIDADE --- //
      analiseSegundaQualidade.forEach(obj => {
        obj = undefinedToEmptyStr(obj);
        params[i].analiseSegundaQualidade.push(
          {
            area: obj.area,
            grupoAnalise: obj.grupo_defeito,
            grade: obj.descricao_grade,
            status: obj.status_defeito,
            defeito: obj.defeito,
            // localizacao_defeito: obj.localizacao_defeito
            consideracao: obj.consideracao
          }
        );
      });

      // --- INSERE ORIENTAÇÃO CONSERTO --- //
      orientacoesConserto.forEach(obj => {
        obj = undefinedToEmptyStr(obj);
        params[i].orientacoesConserto.push(
          {
            grupoAnalise: obj.grupo,
            orientacao: obj.orientacao
          }
        );
      });
    }

    const dadosFichaTecnica = params;

    const heightGrayRow = 18

    const subTitulosFont = {
      name: 'Century Gothic',
      family: 4,
      size: 11,
    }

    const grayColumnsFont = {
      name: 'Century Gothic',
      color: { argb: '404040' },
      family: 4,
      size: 8,
      bold: true
    }

    const contentColumnsFont = {
      name: 'Century Gothic',
      family: 4,
      size: 9
    }

    const statusFont = {
      name: 'Century Gothic',
      family: 4,
      size: 11,
      bold: true
    }

    const fillGrayCell = {
      type: 'pattern',
      pattern: 'darkTrellis',
      fgColor: { argb: 'd9d9d9' }
    }

    const alignmentGrayCell = {
      horizontal: 'center',
      vertical: 'middle'
    }

    const alignmentContentCell = {
      horizontal: 'center',
      vertical: 'middle'
    }

    const borderCell = {
      top: { style: "hair" },
      left: { style: "hair" },
      bottom: { style: "hair" },
      right: { style: "hair" }
    }

    const objectStructureGrayCell =
    {
      estruturaIdentEntradaFiscal1: {
        mergedColumns: [['A', 'E'], ['F', 'I'], ['J', 'N'], ['O', 'S'], ['T', 'X'], ['Y', 'AB']],
        contentColumns: [['A', 'NF Entrada'], ['F', 'Serie NF'], ['J', 'Ordem Produção'], ['O', 'Valor Item NF'], ['T', 'Data Digitação'], ['Y', 'Qtde Recebida']]
      },
      estruturaIdentEntradaFiscal2: {
        mergedColumns: [['A', 'E'], ['F', 'L'], ['M', 'S'], ['T', 'X'], ['Y', 'AB']],
        contentColumns: [['A', 'Compradora'], ['F', 'Filial Revisão'], ['M', 'Fornecedor'], ['T', 'Qtde Recebido 2ª Q'], ['Y', 'Qtde Enviada 2ª Q']]
      },
      estruturaIdentProduto1: {
        mergedColumns: [['A', 'D'], ['E', 'N'], ['O', 'R'], ['S', 'AB']],
        contentColumns: [['A', 'Produto'], ['E', 'Descrição Produto'], ['O', 'Cor Produto'], ['S', 'Descrição Cor Produto']]
      },
      estruturaIdentProduto2: {
        mergedColumns: [['A', 'D'], ['E', 'H'], ['I', 'N'], ['O', 'T'], ['U', 'X'], ['Y', 'AB']],
        contentColumns: [['A', 'Marca'], ['E', 'Coleção'], ['I', 'Estilista'], ['O', 'Tipo Produto'], ['U', 'Linha'], ['Y', 'Grupo Produto']]
      },
      estruturaDadosAnaliseCQ: {
        mergedColumns: [['A', 'D'], ['E', 'H'], ['I', 'K'], ['L', 'N'], ['O', 'U'], ['V', 'AB']],
        contentColumns: [['A', 'Área'], ['E', 'Data Análise'], ['I', 'Amostra'], ['L', '% Revisado'], ['O', 'Colaborador'], ['V', 'Status']]
      },
      estruturaIdentDesenvolvimento: {
        mergedColumns: [['A', 'E'], ['F', 'J'], ['K', 'N'], ['O', 'S'], ['T', 'W'], ['X', 'AB']],
        contentColumns: [['A', 'Modelagem Estilo'], ['F', 'Peça Lacrada'], ['K', 'Corte'], ['O', 'Graduação'], ['T', 'Beneficiamento'], ['X', 'Rec. Peça Piloto']]
      },
      estruturaControleAprovacoes: {
        mergedColumns: [['A', 'D'], ['E', 'H'], ['I', 'N'], ['O', 'U'], ['V', 'AB']],
        contentColumns: [['A', 'Área'], ['E', 'Data Envio'], ['I', 'Motivo Envio'], ['O', 'Status'], ['V', 'Consideração']]
      },
      estruturaMedidas: {
        mergedColumns: [['A', 'C'], ['D', 'F'], ['G', 'N'], ['O', 'S'], ['T', 'W'], ['X', 'AB']],
        contentColumns: [['A', 'Área'], ['D', 'Grade'], ['G', 'Medida'], ['O', 'Tamanho FT'], ['T', 'Tolerância'], ['X', 'Tamanho Médio']]
      },
      estruturaDestinoProducao: {
        mergedColumns: [['A', 'T'], ['U', 'AB']],
        contentColumns: [['A', 'Destino Produção'], ['U', 'Qtde Peças']]
      },
      estruturaTotalPecasDefeitoGrade: {
        mergedColumns: [['A', 'F'], ['G', 'K'], ['L', 'Q'], ['R', 'V'], ['W', 'AB']],
        contentColumns: [['A', 'Tamanho'], ['G', 'Total de Peças Defeito'], ['L', 'Total Revisão'], ['R', 'Total Checklist'], ['W', 'Total Segunda Qualidade']]
      },
      estruturaAvaliacaoProducao: {
        mergedColumns: [['A', 'C'], ['D', 'H'], ['I', 'K'], ['L', 'Q'], ['R', 'V'], ['W', 'AB']],
        contentColumns: [['A', 'Área'], ['D', 'Grupo Análise'], ['I', 'Grade'], ['L', 'Status'], ['R', 'Defeito'], ['W', 'Consideração']]
      },
      estruturaOrientacoesConserto: {
        mergedColumns: [['A', 'G'], ['H', 'AB']],
        contentColumns: [['A', 'Grupo Análise'], ['H', 'Orientação']]
      }
    }

    for (let i = 0; i < dadosFichaTecnica.length; i++) {
      const keys = Object.keys(dadosFichaTecnica[i].identEntradaFiscal1[0]);
      const errObj = []
      for (let p = 0; p < keys.length; p++) {
        if (dadosFichaTecnica[i].identEntradaFiscal1[0][keys[p]] === null) {
          errObj.push([keys[p]]);
        }
      }
      if (errObj.length > 0) return errObj;

      const workbook = new ExcelJS.Workbook();

      const worksheet = workbook.addWorksheet("temp_laudo",
        {
          views: [{ showGridLines: false }],
          pageSetup: { paperSize: 9, fitToPage: true, fitToWidth: 1, fitToHeight: 0 },
          properties: { tabColor: { argb: 'FF00FF00' } }
        }
      )

      const primeiraLinhaCabecalho = worksheet.addRow()

      worksheet.mergeCells(`A${primeiraLinhaCabecalho['_number']}:T${primeiraLinhaCabecalho['_number'] + 3}`)
      worksheet.mergeCells(`U${primeiraLinhaCabecalho['_number']}:AB${primeiraLinhaCabecalho['_number'] + 3}`)

      const tituloCabecalho = worksheet.getCell(`A${primeiraLinhaCabecalho['_number']}`)
      tituloCabecalho.value = '   Ficha Inspeção de Qualidade'
      tituloCabecalho.font = {
        name: 'Century Gothic',
        family: 4,
        size: 16,
      }
      tituloCabecalho.border = {
        top: { style: "medium" },
        left: { style: "medium" },
        bottom: { style: "medium" }
      }
      tituloCabecalho.alignment = {
        vertical: 'middle'
      }

      const imageCabecalho = worksheet.getCell(`U${primeiraLinhaCabecalho['_number']}`)

      const imageBuffer = await axios.get('img/soma_trans.png', { responseType: 'arraybuffer' });
      const imageId = workbook.addImage({
        buffer: imageBuffer.data,
        extension: 'png',
      });

      worksheet.addImage(
        imageId,

        `U${primeiraLinhaCabecalho['_number'] + 1}:AA${primeiraLinhaCabecalho['_number'] + 2}`);

      imageCabecalho.border = {
        top: { style: "medium" },
        right: { style: "medium" },
        bottom: { style: "medium" }
      }
      imageCabecalho.alignment = {
        vertical: 'bottom'
      }

      worksheet.addRow()
      // Identificação Entrada Fiscal
      const subTituloIdenEntradaFiscal = worksheet.addRow(['Identificação Entrada Fiscal'])
      subTituloIdenEntradaFiscal.font = subTitulosFont
      worksheet.mergeCells(`A${subTituloIdenEntradaFiscal['_number']}:H${subTituloIdenEntradaFiscal['_number']}`);

      createGrayRow(objectStructureGrayCell.estruturaIdentEntradaFiscal1)
      addContentColumn(objectStructureGrayCell.estruturaIdentEntradaFiscal1, dadosFichaTecnica[i].identEntradaFiscal1)
      worksheet.addRow()
      createGrayRow(objectStructureGrayCell.estruturaIdentEntradaFiscal2)
      addContentColumn(objectStructureGrayCell.estruturaIdentEntradaFiscal2, dadosFichaTecnica[i].identEntradaFiscal2)
      worksheet.addRow()

      // Identificação do Produto
      const subTituloIdenProduto = worksheet.addRow(['Identificação do Produto'])
      subTituloIdenProduto.font = subTitulosFont
      worksheet.mergeCells(`A${subTituloIdenProduto['_number']}:H${subTituloIdenProduto['_number']}`);

      createGrayRow(objectStructureGrayCell.estruturaIdentProduto1)
      addContentColumn(objectStructureGrayCell.estruturaIdentProduto1, dadosFichaTecnica[i].identProduto1)
      worksheet.addRow()
      createGrayRow(objectStructureGrayCell.estruturaIdentProduto2)
      addContentColumn(objectStructureGrayCell.estruturaIdentProduto2, dadosFichaTecnica[i].identProduto2)
      worksheet.addRow()

      // STATUS QUALIDADE / STATUS FINAL
      const subTituloStatusQualidade = worksheet.addRow(['STATUS QUALIDADE'])
      subTituloStatusQualidade.font = statusFont
      subTituloStatusQualidade.height = 26
      subTituloStatusQualidade.alignment = { vertical: 'middle' }

      worksheet.mergeCells(`A${subTituloStatusQualidade['_number']}:N${subTituloStatusQualidade['_number']}`);
      worksheet.mergeCells(`O${subTituloStatusQualidade['_number']}:AB${subTituloStatusQualidade['_number']}`);

      const contentStatusQualidade = worksheet.getCell(`O${subTituloStatusQualidade['_number']}`)
      contentStatusQualidade.value = dadosFichaTecnica[i].statusQualidade
      contentStatusQualidade.fill = fillGrayCell
      contentStatusQualidade.alignment = alignmentContentCell

      worksheet.addRow().height = 5

      const subTituloStatusFinal = worksheet.addRow(['STATUS FINAL'])
      subTituloStatusFinal.font = statusFont
      subTituloStatusFinal.height = 26
      subTituloStatusFinal.alignment = { vertical: 'middle' }

      worksheet.mergeCells(`A${subTituloStatusFinal['_number']}:N${subTituloStatusFinal['_number']}`);
      worksheet.mergeCells(`O${subTituloStatusFinal['_number']}:AB${subTituloStatusFinal['_number']}`);

      const contentStatusFinal = worksheet.getCell(`O${subTituloStatusFinal['_number']}`)
      contentStatusFinal.value = dadosFichaTecnica[i].statusFinal
      contentStatusFinal.fill = fillGrayCell
      contentStatusFinal.alignment = alignmentContentCell

      // Dados Análise CQ
      if (dadosFichaTecnica[i].identDesenvolvimento && dadosFichaTecnica[i].identDesenvolvimento.length > 0) {
        const subTituloDadosAnaliseCQ = worksheet.addRow(['Dados Análise CQ'])
        subTituloDadosAnaliseCQ.font = subTitulosFont
        worksheet.mergeCells(`A${subTituloDadosAnaliseCQ['_number']}:H${subTituloDadosAnaliseCQ['_number']}`);

        createGrayRow(objectStructureGrayCell.estruturaDadosAnaliseCQ)
        addContentColumn(objectStructureGrayCell.estruturaDadosAnaliseCQ, dadosFichaTecnica[i].dadosAnaliseCQ)
        worksheet.addRow()

        // Identificação de Desenvolvimento
        const subTituloIdentDesenvolvimento = worksheet.addRow(['Identificação de Desenvolvimento'])
        subTituloIdentDesenvolvimento.font = subTitulosFont
        worksheet.mergeCells(`A${subTituloIdentDesenvolvimento['_number']}:J${subTituloIdentDesenvolvimento['_number']}`);

        createGrayRow(objectStructureGrayCell.estruturaIdentDesenvolvimento)
        addContentColumn(objectStructureGrayCell.estruturaIdentDesenvolvimento, dadosFichaTecnica[i].identDesenvolvimento)
        worksheet.addRow()
      }

      // Controle Aprovações
      if (dadosFichaTecnica[i].controleAprovacoes && dadosFichaTecnica[i].controleAprovacoes.length > 0) {
        const subTituloControleAprovacoes = worksheet.addRow(['Controle Aprovações'])
        subTituloControleAprovacoes.font = subTitulosFont
        worksheet.mergeCells(`A${subTituloControleAprovacoes['_number']}:J${subTituloControleAprovacoes['_number']}`);

        createGrayRow(objectStructureGrayCell.estruturaControleAprovacoes)
        addContentColumn(objectStructureGrayCell.estruturaControleAprovacoes, dadosFichaTecnica[i].controleAprovacoes)
        worksheet.addRow()
      }

      // Medidas
      if (dadosFichaTecnica[i].medidas && dadosFichaTecnica[i].medidas.length > 0) {
        const subTituloMedidas = worksheet.addRow(['Medidas'])
        subTituloMedidas.font = subTitulosFont
        worksheet.mergeCells(`A${subTituloMedidas['_number']}:C${subTituloMedidas['_number']}`);

        createGrayRow(objectStructureGrayCell.estruturaMedidas)
        addContentColumn(objectStructureGrayCell.estruturaMedidas, dadosFichaTecnica[i].medidas)
        worksheet.addRow()
      }

      // Destino Produção
      if (dadosFichaTecnica[i].destinoProducao && dadosFichaTecnica[i].destinoProducao.length > 0) {
        const subTituloDestinoProducao = worksheet.addRow(['Destino Produção'])
        subTituloDestinoProducao.font = subTitulosFont
        worksheet.mergeCells(`A${subTituloDestinoProducao['_number']}:F${subTituloDestinoProducao['_number']}`);

        createGrayRow(objectStructureGrayCell.estruturaDestinoProducao)
        addContentColumn(objectStructureGrayCell.estruturaDestinoProducao, dadosFichaTecnica[i].destinoProducao)
        worksheet.addRow()
      }

      // Total de Peças Defeito x Grade
      if (dadosFichaTecnica[i].totalPecasDefeitoGrade && dadosFichaTecnica[i].totalPecasDefeitoGrade.length > 0) {
        const subTituloTotalPecasDefeitoGrade = worksheet.addRow(['Total de Peças Defeito x Grade'])
        subTituloTotalPecasDefeitoGrade.font = subTitulosFont

        worksheet.mergeCells(`A${subTituloTotalPecasDefeitoGrade['_number']}:Q${subTituloTotalPecasDefeitoGrade['_number']}`);
        worksheet.mergeCells(`R${subTituloTotalPecasDefeitoGrade['_number']}:V${subTituloTotalPecasDefeitoGrade['_number']}`);
        worksheet.mergeCells(`W${subTituloTotalPecasDefeitoGrade['_number']}:AB${subTituloTotalPecasDefeitoGrade['_number']}`);

        const cellTotalPecasDefeito = worksheet.getCell(`R${subTituloTotalPecasDefeitoGrade['_number']}`)
        cellTotalPecasDefeito.value = 'Total peças defeito:'
        cellTotalPecasDefeito.font = {
          name: 'Century Gothic',
          family: 4,
          size: 9,
          bold: true
        }
        cellTotalPecasDefeito.alignment = alignmentGrayCell

        const cellValueTotalPecasDefeito = worksheet.getCell(`W${subTituloTotalPecasDefeitoGrade['_number']}`)
        cellValueTotalPecasDefeito.value = dadosFichaTecnica[i].totalPecasDefeitoGrade.length
        cellValueTotalPecasDefeito.font = statusFont
        cellValueTotalPecasDefeito.alignment = alignmentGrayCell

        createGrayRow(objectStructureGrayCell.estruturaTotalPecasDefeitoGrade)
        addContentColumn(objectStructureGrayCell.estruturaTotalPecasDefeitoGrade, dadosFichaTecnica[i].totalPecasDefeitoGrade)
        worksheet.addRow()
      }

      // Avaliação da Produção
      if (dadosFichaTecnica[i].avaliacaoProducao && dadosFichaTecnica[i].avaliacaoProducao.length > 0) {
        const subTituloAvaliacaoProducao = worksheet.addRow(['Avaliação da Produção'])
        subTituloAvaliacaoProducao.font = subTitulosFont
        worksheet.mergeCells(`A${subTituloAvaliacaoProducao['_number']}:G${subTituloAvaliacaoProducao['_number']}`);

        createGrayRow(objectStructureGrayCell.estruturaAvaliacaoProducao)
        addContentColumn(objectStructureGrayCell.estruturaAvaliacaoProducao, dadosFichaTecnica[i].avaliacaoProducao)
        worksheet.addRow()
      }

      // Análise Segunda Qualidade
      if (dadosFichaTecnica[i].analiseSegundaQualidade && dadosFichaTecnica[i].analiseSegundaQualidade.length > 0) {
        const subTituloAnaliseSegundaQualidade = worksheet.addRow(['Avaliação Segunda Qualidade'])
        subTituloAnaliseSegundaQualidade.font = subTitulosFont
        worksheet.mergeCells(`A${subTituloAnaliseSegundaQualidade['_number']}:J${subTituloAnaliseSegundaQualidade['_number']}`);

        createGrayRow(objectStructureGrayCell.estruturaAvaliacaoProducao)
        addContentColumn(objectStructureGrayCell.estruturaAvaliacaoProducao, dadosFichaTecnica[i].analiseSegundaQualidade)
        worksheet.addRow()
      }

      // Orientações para Conserto
      if (dadosFichaTecnica[i].orientacoesConserto && dadosFichaTecnica[i].orientacoesConserto.length > 0) {
        const subTituloOrientacoesConserto = worksheet.addRow(['Orientações para Conserto'])
        subTituloOrientacoesConserto.font = subTitulosFont
        worksheet.mergeCells(`A${subTituloOrientacoesConserto['_number']}:H${subTituloOrientacoesConserto['_number']}`);

        createGrayRow(objectStructureGrayCell.estruturaOrientacoesConserto)
        addContentColumn(objectStructureGrayCell.estruturaOrientacoesConserto, dadosFichaTecnica[i].orientacoesConserto)
        worksheet.addRow()
      }

      if (dadosFichaTecnica[i].imagensProducao.length > 0) {
        // Quebra de página para que as imagens tenham uma página própria
        const rowsCount = worksheet.getColumn(1)['_worksheet']['_rows'].length;
        const lastRow = worksheet.getRow(rowsCount);
        lastRow.addPageBreak();

        // Imagens Produção
        const subTituloImagensProducao = worksheet.addRow(['Imagens Produção']);
        subTituloImagensProducao.font = subTitulosFont;
        worksheet.mergeCells(`A${subTituloImagensProducao['_number']}:T${subTituloImagensProducao['_number']}`);

        await inserirImagens(dadosFichaTecnica[i].imagensProducao, workbook, worksheet, subTituloImagensProducao);
      }

      if (dadosFichaTecnica[i].imagensSegundaQualidade.length > 0) {
        // Quebra de página para que as imagens tenham uma página própria
        const rowsCount = worksheet.getColumn(1)['_worksheet']['_rows'].length;
        const lastRow = worksheet.getRow(rowsCount);
        lastRow.addPageBreak();

        // Imagens Segunda Qualidade
        const subTituloImagensSegundaQualidade = worksheet.addRow(['Imagens Segunda Qualidade']);
        subTituloImagensSegundaQualidade.font = subTitulosFont;
        worksheet.mergeCells(`A${subTituloImagensSegundaQualidade['_number']}:T${subTituloImagensSegundaQualidade['_number']}`);

        await inserirImagens(dadosFichaTecnica[i].imagensSegundaQualidade, workbook, worksheet, subTituloImagensSegundaQualidade);
      }

      // Observações
      const observacao = worksheet.addRow()
      observacao.height = 28.5
      worksheet.mergeCells(`A${observacao['_number']}:AB${observacao['_number']}`);
      const cellObservacao = worksheet.getCell(`A${observacao['_number']}`)
      // cellObservacao.value = 'OBS: Compras/ PCP, enviar comunicado ao Fornecedor. Em caso de dúvidas, entrar em contato com o departamento de Controle de Qualidade Ramal: 7165 (FFF) e 7285 (AAF).'
      cellObservacao.value = 'OBS: Compras/ PCP, enviar comunicado ao Fornecedor.'
      cellObservacao.font = contentColumnsFont
      cellObservacao.fill = fillGrayCell
      cellObservacao.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true }

      worksheet.addRow()

      function createGrayRow(objectStructureGrayCell) {
        const grayRow = worksheet.addRow()

        grayRow.font = grayColumnsFont
        grayRow.alignment = alignmentGrayCell
        grayRow.height = heightGrayRow

        objectStructureGrayCell.mergedColumns.forEach(column => {
          worksheet.mergeCells(`${column[0] + grayRow['_number']}:${column[1] + grayRow['_number']}`);
        })

        objectStructureGrayCell.contentColumns.forEach(contentColumn => {
          const cellAgrayRow = worksheet.getCell(`${contentColumn[0] + grayRow['_number']}`)
          cellAgrayRow.value = contentColumn[1]
          cellAgrayRow.fill = fillGrayCell
          cellAgrayRow.border = borderCell
        });

      }

      function addContentColumn(objectStructureGrayCell, contentColumns) {
        contentColumns.forEach((contentColumn) => {
          const contentRow = worksheet.addRow()

          contentRow.font = contentColumnsFont
          contentRow.alignment = alignmentContentCell

          objectStructureGrayCell.mergedColumns.forEach(column => {
            worksheet.mergeCells(`${column[0] + contentRow['_number']}:${column[1] + contentRow['_number']}`);
          })

          let contentCell = []
          let configHeigth = 0

          Object.values(contentColumn).forEach((content, index) => {
            contentCell.push([objectStructureGrayCell.contentColumns[index][0], content])
          })

          contentCell.forEach(contentColumn => {
            const cellContentRow = worksheet.getCell(`${contentColumn[0] + contentRow['_number']}`)
            cellContentRow.value = contentColumn[1]
            cellContentRow.border = borderCell
            cellContentRow.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true }

            if (contentColumn[1] === undefined || contentColumn[1] === '') contentColumn[1] = '-';

            if (contentColumn[1].length > 20) {
              configHeigth = contentColumn[1].length > configHeigth ? contentColumn[1].length : configHeigth
              contentRow.height = configHeigth
            }
          });
        });
      }

      // Config geral de tamanho de colunas 
      worksheet.columns.forEach(function (column, i) {
        column.width = 3.7;
      })

      workbook.xlsx.writeBuffer()
        .then((file) => {
          const uri = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; base64,' + file.toString("base64");

          // this will generate a temp <a /> tag
          var link = document.createElement("a");
          link.href = uri;

          // set the visibility hidden so it will not effect on your web-layout
          link.style = "visibility:hidden";
          link.download = `${dadosFichaTecnica[i].nomeArquivo}.xlsx`;

          // this part will append the anchor tag and remove it after automatic click
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    }
  } catch (e) {
    toast.error('Ocorreu um erro ao gerar os laudos, por favor tento novamente ou entre em contato com o suporte.');
  }
}

const calcAmostra = (QtdeRecebida, QtdeRecebida2Q, QtdeAmostra) => {
  if (QtdeRecebida - QtdeRecebida2Q > 0) {
    QtdeAmostra = (QtdeAmostra * 100) / ((QtdeRecebida - QtdeRecebida2Q));
  } else {
    QtdeAmostra = (QtdeAmostra * 100) / (QtdeRecebida);
  }
  return QtdeAmostra.toFixed(2);
}

const undefinedToEmptyStr = (obj) => {
  const keys = Object.keys(obj);
  keys.forEach(key => {
    if (obj[key] === undefined || obj[key] === null) {
      obj[key] = ' ';
    }
  });
  return obj;
}
