import React, { useEffect, useState } from 'react';
import styles from '../../../styles/Corte/ControleEnfesto/SelecaoRiscos.module.scss';
import { toast } from 'react-toastify';
import { api } from "../../../services/api";
import MainTable from '../../../components/Corte/ControleEnfesto/SelecaoRiscos/MainTable';
import ModalConfirmacao from '../../../components/Corte/ControleEnfesto/SelecaoRiscos/ModalConfirmacao';
import Filter from '../../../components/Corte/ControleEnfesto/SelecaoRiscos/Filter';
import { Button } from 'react-bootstrap';
import CookiesService from '../../../services/cookies';
import { toDatetime } from '../../../utils/dateUtils';
import { useMisc } from '../../../contexts/MiscContext';

const SelecaoRiscos = () => {
    const [mainData, setMainData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedValuesFiltro, setSelectedValuesFiltro] = useState({});
    const [linhasSelecionadas, setLinhasSelecionadas] = useState([]);
    const [toggledClearRows, setToggleClearRows] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showConfirmacao, setShowConfirmacao] = useState(false);
    const [showFiltro, setShowFiltro] = useState(false);
    const { setShowLoading } = useMisc();

    const userLogin = CookiesService.getUserLogin();

    useEffect(() => {
        handleSearch();
    }, []);

    useEffect(() => {
        const applyFilters = () => {
            if (Object.keys(selectedValuesFiltro).length === 0) {
                setFilteredData(mainData);
                return;
            }

            const filtered = mainData.filter(item => 
                Object.keys(selectedValuesFiltro).every(attribute =>
                    selectedValuesFiltro[attribute].includes(item[attribute])
                )
            );

            setFilteredData(filtered);
        };

        applyFilters();
    }, [selectedValuesFiltro, mainData]);

    const handleSearch = async () => {
        setLoading(true);
        try {
            const res = await api.get('Corte/ControleEnfesto/SelecaoRiscos/get-rows');
            setMainData(res.data);
            setFilteredData(res.data);
        } catch (e) {
            toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre em contato com o suporte');
            console.error(`Erro na requisição - ${e}`);
        } finally {
            setLoading(false);
            setToggleClearRows(false);
        }
    };

    const handleProcessar = async (riscos) => {
        const params = { riscos, info: { user: userLogin, date: toDatetime(new Date()) } };
        setShowLoading(true);

        try {
            const res = await api.post('Corte/ControleEnfesto/SelecaoRiscos/envia-risco', { params });
            if (res.status === 200) {
                handleReset();
                toast.success('Dados inseridos com sucesso!');
                handleSearch();
            } else {
                toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
            }
        } catch (e) {
            toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre em contato com o suporte');
            console.error(`Erro na requisição - ${e}`);
        } finally {
            setShowLoading(false);
        }
    };

    const handleCheckboxChange = (value, attribute) => {
        setSelectedValuesFiltro(prevState => {
            const updatedValues = { ...prevState };
            if (!updatedValues[attribute]) {
                updatedValues[attribute] = [];
            }
            const isSelected = updatedValues[attribute].includes(value);
            if (isSelected) {
                updatedValues[attribute] = updatedValues[attribute].filter(item => item !== value);
                if (updatedValues[attribute].length === 0) {
                    delete updatedValues[attribute];
                }
            } else {
                updatedValues[attribute] = [...updatedValues[attribute], value];
            }
            return updatedValues;
        });
    };

    const handleClearCurrentFilter = (attribute) => {
        setSelectedValuesFiltro(prevState => {
            const updatedValues = { ...prevState };
            delete updatedValues[attribute];
            return updatedValues;
        });
    };

    const handleShowConfirmacao = () => {
        if (linhasSelecionadas.length === 0) {
            toast.warning('Ao menos um risco deverá ser selecionado');
        } else {
            setShowConfirmacao(true);
        }
    };

    const handleCloseConfirmacao = () => setShowConfirmacao(false);

    const handleShowFiltro = () => setShowFiltro(true);

    const handleCloseFiltro = () => setShowFiltro(false);

    const handleLimparFiltro = () => setSelectedValuesFiltro({});

    const handleCheckLinhas = ({ selectedRows }) => setLinhasSelecionadas(selectedRows);

    const handleClearRows = () => setToggleClearRows(!toggledClearRows);

    const handleReset = () => {
        handleClearRows();
        setLinhasSelecionadas([]);
        handleCloseConfirmacao();
    };

    return (
        <div>
            <article className={styles.articleTitle}>
                <h3 className="p-3">Corte - Seleção dos Riscos</h3>
            </article>

            <article className={styles.articleGray}>
                <span>
                    <Button
                        variant="outline-info"
                        className={styles.modalBtn}
                        style={{ margin: '20px 20px' }}
                        onClick={handleShowFiltro}
                    >
                        Filtro
                    </Button>
                    <Button
                        variant="outline-warning"
                        className={styles.modalBtn}
                        style={{ margin: '20px 20px' }}
                        onClick={handleLimparFiltro}
                    >
                        Limpar Filtro
                    </Button>
                </span>
                <span style={{ position: 'absolute', right: '20px' }}>
                    <Button
                        variant="primary"
                        className={styles.modalBtn}
                        onClick={handleShowConfirmacao}
                    >
                        Enviar Cad
                    </Button>
                </span>
                <MainTable
                    data={filteredData}
                    pending={loading}
                    onSelectedRowsChange={handleCheckLinhas}
                    clearSelectedRows={toggledClearRows}
                />
            </article>

            <ModalConfirmacao
                show={showConfirmacao}
                handleCloseConfirmacao={handleCloseConfirmacao}
                linhasSelecionadas={linhasSelecionadas}
                handleProcessar={handleProcessar}
            />

            <Filter
                data={mainData}
                show={showFiltro}
                setShow={setShowFiltro}
                close={handleCloseFiltro}
                handleClearCurrentFilter={handleClearCurrentFilter}
                handleCheckboxChange={handleCheckboxChange}
                selectedValuesFiltro={selectedValuesFiltro}
            />
        </div>
    );
};

export default SelecaoRiscos;
