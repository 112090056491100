import React, { useState } from 'react';
import Tabela from '../../Misc/Tabela/Tabela';
import TabelaDND from '../../Misc/TabelaDND/tabela_dnd';
import { Button } from 'react-bootstrap';
import { usePARevisao } from './pa_revisao_context';
import PARevisaoDirecionamento from './components/pa_revisao_modal_direcionamento';
import PARevisaoGrade from './components/pa_revisao_modal_grade';
import styles from './pa_revisao.module.scss'
import PARevisaoModalConfirmacaoSQ from './components/pa_revisao_modal_confirmacao_sq';
import PARevisaoModalProcessamento from './components/pa_revisao_modal_processamento';
import FiltroRevisao from './components/pa_revisao_filtro';
import ListarImagemModal from '../../Misc/ListarImagens/listar_imagens_modal';
import PARevisaoModalInfo from './components/pa_revisao_modal_informacoes';
import PARevisaoModalLocalizacao from './components/pa_revisao_localizacao';
import MaterialIcon from 'material-icons-react';
import PARevisaoModalDespriorizacao from './components/pa_revisao_modal_despriorizacao';

const PARevisaoMain = () => {
  const { listaRevisaoFiltrada, iniciarRevisao, modalRevisao, modalDirecionamento, modalConfirmacaoSQ, modalProcessamento, resetarTarefas, handleAdmAlterar, revisaoDesabilitada, usuarioAnalistaPrio, isAdmin, modalImagens, fecharModalImagens, adicionarImagens, removerImagem, direcionamentoRevisao, modalInfo, abrirModalInformacoes, showModalInformacoes, showModalLocalizacao, visaoAnalista, showModalDespriorizacao, setShowModalDespriorizacao } = usePARevisao();

  const [visaoPriorizacao, setVisaoPriorizacao] = useState(false);

  const conditionalRowStyles = [
    {
      when: row => row.priorizado,
      style: {
        '&:nth-child(odd)': { backgroundColor: '#FFF8DA' },
        '&:nth-child(even)': { backgroundColor: '#FFFBEA' }
      }
    },
    {
      when: row => row.em_revisao,
      style: {
        '&:nth-child(odd)': { backgroundColor: '#CCFFDA50' },
        '&:nth-child(even)': { backgroundColor: '#CCFFDA80' }
      }
    }
  ];

  const revisaoColumnsOperacao = [
    {
      cell: row => {
        return (
          <Button
            style={{ fontWeight: '500' }}
            variant={row.nf_segunda_qualidade || row.produto_de_couro ? 'warning' : 'primary'}
            onClick={() => iniciarRevisao(row)}
            disabled={row.priorizado && !row.em_revisao ? false : revisaoDesabilitada(row.em_revisao, row.id_entrada_fiscal)}
          >
            {row.nf_segunda_qualidade || row.produto_de_couro ? 'Rev. 100%' : 'Revisão'}
          </Button>
        )
      }, width: '160px'
    },
    { name: 'NF Entrada', selector: row => row.nf_entrada },
    { name: 'Serie NF', selector: row => row.serie_nf },
    { name: 'Origem', selector: row => row.origem },
    { name: 'Filial Revisão', selector: row => row.filial_revisao },
    { name: 'Produto', selector: row => row.produto },
    { name: 'Desc. Produto', selector: row => row.descricao_produto },
    { name: 'Cor', selector: row => row.cor_produto },
    { name: 'Desc. Cor', selector: row => row.descricao_cor },
    { name: 'Qtde.', selector: row => row.qtde_entrada },
    { name: 'Ordem Produção', selector: row => row.ordem_producao },
    { name: 'Coleção', selector: row => row.colecao },
    { name: 'Linha', selector: row => row.linha },
    { name: 'Preço', selector: row => row.preco },
    { name: 'Data Entrada Revisão', selector: row => row.data_entrada_revisao_br }
  ];

  const revisaoColumnsAnalise = [
    {
      cell: (row, index) => {
        return (
          <Button
            style={{ fontWeight: '500' }}
            variant={'primary'}
            onClick={() => abrirModalInformacoes(row)}
          >
            Informações
          </Button>
        )
      }, width: '160px'
    },
    {
      name: 'Localizado Revisão?', selector: row => {
        return row.localizacao_na_revisao ?
          <div className={styles.loc_label_true}>Sim</div> :
          <div className={styles.loc_label_false}>Não</div>
      }, width: '160px'
    },
    { name: 'NF Entrada', selector: row => row.nf_entrada },
    { name: 'Serie NF', selector: row => row.serie_nf },
    { name: 'Origem', selector: row => row.origem },
    { name: 'Filial Revisão', selector: row => row.filial_revisao },
    { name: 'Produto', selector: row => row.produto },
    { name: 'Desc. Produto', selector: row => row.descricao_produto },
    { name: 'Cor', selector: row => row.cor_produto },
    { name: 'Desc. Cor', selector: row => row.descricao_cor },
    { name: 'Qtde.', selector: row => row.qtde_entrada },
    { name: 'Ordem Produção', selector: row => row.ordem_producao },
    { name: 'Coleção', selector: row => row.colecao },
    { name: 'Linha', selector: row => row.linha },
    { name: 'Preço', selector: row => row.preco },
    { name: 'Data Entrada Revisão', selector: row => row.data_entrada_revisao_br }
  ];

  return (
    <section className={styles.revisao_body_wrapper}>
      <section className={styles.flex_title}>
        <h4 className={styles.title}>Revisão PA</h4>
      </section>
      <section className={styles.header_section} style={{ marginBottom: '20px !important' }}>
        <div className={styles.adm_btn_wrapper}>
          <FiltroRevisao />
        </div>

        {
          (usuarioAnalistaPrio || isAdmin) &&
          <>
            <div className={styles.separator} />
            <div className={styles.adm_btn_wrapper}>
              <Button
                className={styles.btn_big_header}
                variant={'warning'}
                onClick={() => setShowModalDespriorizacao(true)}
                disabled={!visaoPriorizacao}
                style={{ visibility: visaoPriorizacao ? 'initial' : 'hidden', marginLeft: 'auto' }}
              >
                Despriorizar Tudo
              </Button>
              <Button
                className={styles.btn_big_header}
                variant={'secondary'}
                onClick={resetarTarefas}
                disabled={!visaoPriorizacao}
                style={{ visibility: visaoPriorizacao ? 'initial' : 'hidden', alignSelf: 'flex-end', fontWeight: '500', alignItems: 'center', display: 'flex' }}
              >
                <MaterialIcon
                  icon="undo"
                  size={15}
                  color='#FFFFFF'
                />
              </Button>
              <Button
                className={styles.btn_big_header}
                variant={'success'}
                onClick={handleAdmAlterar}
                disabled={!visaoPriorizacao}
                style={{ visibility: visaoPriorizacao ? 'initial' : 'hidden' }}
              >
                Salvar Alterações
              </Button>
              <Button
                className={styles.btn_big_header}
                variant={'primary'}
                disabled={false}
                onClick={() => setVisaoPriorizacao(!visaoPriorizacao)}
                style={{ alignSelf: 'flex-end', fontWeight: '500', alignItems: 'center', display: 'flex' }}
              >
                {visaoPriorizacao ? 'Versão Padrão' : 'Versão de Priorização'}
                &nbsp;
                <MaterialIcon
                  icon="autorenew"
                  size={15}
                  color='#FFFFFF'
                />
              </Button>
            </div>
          </>
        }
      </section>
      <section style={{ margin: '0px 20px 20px' }}>
        {!visaoPriorizacao &&
          <Tabela
            columns={visaoAnalista ? revisaoColumnsAnalise : revisaoColumnsOperacao}
            data={listaRevisaoFiltrada}
            pending={false}
            pagination={true}
            paginationPerPage={20}
            highlightOnHover={true}
            conditionalRowStyles={conditionalRowStyles}
          />
        }

        {visaoPriorizacao && <TabelaDND />}
      </section>

      {modalInfo && <PARevisaoModalInfo />}
      {modalDirecionamento && <PARevisaoDirecionamento />}
      {modalRevisao && <PARevisaoGrade />}
      {modalConfirmacaoSQ && <PARevisaoModalConfirmacaoSQ />}
      {modalProcessamento && <PARevisaoModalProcessamento />}
      {modalImagens.show &&
        <ListarImagemModal
          showModal={modalImagens.show}
          closeModal={fecharModalImagens}
          imagens={direcionamentoRevisao.length > 0 && direcionamentoRevisao[modalImagens.index].imagens || []}
          direcionamentoIndex={modalImagens.index}
          adicionarImagens={adicionarImagens}
          removerImagem={removerImagem}
        />
      }
      {showModalInformacoes && <PARevisaoModalInfo />}
      {showModalLocalizacao && <PARevisaoModalLocalizacao />}
      {showModalDespriorizacao && <PARevisaoModalDespriorizacao />}
    </section>
  )
}

export default PARevisaoMain;
